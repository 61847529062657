import React, { useEffect, useState } from "react";
import "./App.css";
import {MINION_WIDTH, MINION_HEIGHT} from "./Minion.tsx";
import { MinionList } from "./MinionList.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function MinionListRoute() {

    const
        {
            minionListProps,
            debugRoute,
        } = useLoaderData();
    const [pngURL, setPngURL] = useState(null);

    const dimensions = {
        height: MINION_HEIGHT,
        width: Math.max(minionListProps.minions.length, 1) * MINION_WIDTH,
    }
    console.error({ minionListProps })

    useEffect(() => {
        const generateSvg = async () => {
            const svgResult = await satori(
                <MinionList {...minionListProps} />, {
                height: dimensions.height,
                width: dimensions.width,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
        }
        generateSvg()
    }, [minionListProps,
        debugRoute
    ]);

    return (
        <div style={{display: "flex", flexDirection:"column"}}>
            {debugRoute && debugRoute === "1" && (
                <MinionList {...minionListProps} />
            )}
            <img style={{height:dimensions.height, width:dimensions.width}} src={pngURL!} alt={"MinionList"}></img>
        </div>
    );
}


const exampleRequest = `
http://localhost:3001/minion-list?debugRoute=1&minionListProps={"minions": [{
    "name": "Zombeh",
    "attack": 2,
    "hp": 4,
    "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
    "borderColor": "maroon"
},
{
    "name": "Zombeh",
    "attack": 2,
    "hp": 4,
    "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
    "borderColor": "green"
}]}
`
if(exampleRequest !== null) {

}

// http://localhost:3001/minion-list?debugRoute=1&minionListProps={%22minions%22:%20[{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22maroon%22%20},%20{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22green%22%20}]}