// HexagonGrid.js
import React from 'react';
import HexagonImage from './Hexagon.tsx'; // Adjust the import path as necessary

const gridStyles = {
    grid: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    row: {
        display: 'flex',
        justifyContent: 'center',
    },
    oddRow: {
        marginLeft: '200px', // Half of the hexagon's width to offset odd rows
    },
    offset: {
        marginTop: '-80px'
    }
};

function evenRow(row) {
    return row.length % 2 === 0;
}

function bothSame(row1, row2) {
    if (evenRow(row1) && evenRow(row2)) {
        return true;
    }
    else if (!evenRow(row1) && !evenRow(row2)) {
        return true;
    }
    else return false;
}

const HexagonGrid = ({ urls = [] }) => {
    console.error(urls)
    for (const [index, element] of urls.entries()) {
        if (index === 0) {
            element.offset = false;
        } else {
            if (bothSame(urls[index], urls[index - 1])) {
                element.offset = !urls[index - 1].offset;
            } else {
                element.offset = urls[index - 1].offset;
            }
        }
    }
    return (
        <div style={gridStyles.grid}>
            {urls.map((row, rowIndex) => (
                <div
                    key={rowIndex}
                    style={{
                        ...gridStyles.row,
                        ...(row.offset ? gridStyles.oddRow : {}),
                        ...(rowIndex > 0 ? gridStyles.offset : {}),
                    }}
                >
                    {row.map((url, urlIndex) => (
                        <HexagonImage key={urlIndex} imageUrl={url} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default HexagonGrid;
