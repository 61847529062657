import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from "./Banner.tsx";

export const HeroMessage = ({ height, width, backgroundImgUrl, bannerProps }) => {
    return (
        <div style={{height: height, display: "flex", flexDirection: "column"}}>
            <div style={{ display: "flex", height: height, width: width, backgroundImage: `url(${backgroundImgUrl})`, backgroundSize: "cover" }}>
                <Banner {...bannerProps}></Banner>
            </div>
        </div>
    );
}


HeroMessage.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    backgroundImgUrl: PropTypes.string,
    bannerProps: Banner.propTypes,
};


HeroMessage.defaultProps = {
    height: 534,
    width: 800,
    backgroundImgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Farm800w.png",
    bannerProps: {
        height: 131,
        width: 800,
        heroImgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png",
        text: "Rose: What should we do this week?",
        marginLeft: "100px",
        marginRight: "60px",
        fontSize: "26px",
        top: "34px",
    },
};

// https://54eb614e.imgrender.pages.dev/hero-message?height=534&width=800&backgroundImg_url=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Farm800w.png&banner_height=131&banner_width=800&banner_heroImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png&banner_text=What%20should%20we%20do%20this%20week?&banner_marginLeft=100px&banner_fontSize=26px&banner_top=34px&debugRoute=1

// https://54eb614e.imgrender.pages.dev/hero-message?height=534&width=800&backgroundImg_url=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Temple800w.png&banner_height=131&banner_width=800&banner_heroImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png&banner_text=Pray%20for%20a%20beast&banner_marginLeft=100px&banner_fontSize=26px&banner_top=34px&debugRoute=1

// /hero-message?height=534&width=800&backgroundImg_url=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Temple800w.png&banner_height=131&banner_width=800&banner_heroImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png&banner_text=Pray%20for%20a%20beastPray%20for%20a%20beastPray%20for%20a%20beastPray%20for%20a%20beastPray%20for%20a%20beastPray%20for%20a%20beastPray%20for%20a%20beast&banner_marginLeft=100px&banner_fontSize=23px&banner_top=34px&debugRoute=1&marginRight=60px