import "./App.css";
import Card from "./Card.tsx";
import { downloadSvgAsPng } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";

const roboto = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function App() {
  const { width, height } = useLoaderData();
  console.error("APP")
  console.error({width})
  console.error({height})

  const handleClick = async () => {
    // const width = 1760;
    // const height = 800;
    const width = 100;
    const height = 100;
    const svg = await satori(<Card width={width} height={height} />, {
      width,
      height,
      fonts: [
        {
          name: "Roboto",
          data: await roboto
        }
      ]
    });
  };

  return (
    <>
      <Card />
      <button onClick={handleClick}>Download</button>
    </>
  );
}
