import React from 'react';
import { MinionList } from './MinionList.tsx';
import { MINION_HEIGHT, MINION_WIDTH } from './Minion.tsx';

export const getMinionBoardHeightWidth = (minionsTop, minionsBot) => {
    const height = MINION_HEIGHT * 2 + 35;
    // const width = MINION_WIDTH * Math.max(minionsTop.length, minionsBot.length);
    const width = MINION_WIDTH * 7;
    return { height, width };
}

export const MinionBoard = ({ minionsTop, minionsBot, arrow, statusText }) => {
    const minionBoardDimensions = getMinionBoardHeightWidth(minionsTop, minionsBot);
    console.error({ minionBoardDimensions })

    let xOrigin;
    let yOrigin;
    let xDest;
    let yDest;

    if (arrow) {
        if (arrow.direction === "topToBot") {
            xOrigin = MINION_WIDTH / 2.0 + arrow.originIndex * MINION_WIDTH;
            yOrigin = MINION_HEIGHT / 2.0;
            xDest = MINION_WIDTH / 2.0 + arrow.destIndex * MINION_WIDTH;
            yDest = MINION_HEIGHT / 2.0 + MINION_HEIGHT;
        } else if (arrow.direction === "botToTop") {
            xOrigin = MINION_WIDTH / 2.0 + arrow.originIndex * MINION_WIDTH;
            yOrigin = MINION_HEIGHT / 2.0 + MINION_HEIGHT;
            xDest = MINION_WIDTH / 2.0 + arrow.destIndex * MINION_WIDTH;
            yDest = MINION_HEIGHT / 2.0;
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", minHeight: minionBoardDimensions.height, width: minionBoardDimensions.width, height: minionBoardDimensions.height }}>
            <MinionList minions={minionsTop} />
            <MinionList minions={minionsBot} />
            {arrow && (
                <svg style={{ display: "flex", position: "absolute", zIndex: 100, width: minionBoardDimensions.width, height: minionBoardDimensions.height }}>
                    <defs>
                        <marker id="arrowhead" markerWidth="10" markerHeight="7"
                            refX="10" refY="3.5" orient="auto">
                            <polygon fill="red" points="0 0, 10 3.5, 0 7" />
                        </marker>
                    </defs>
                    <line x1={xOrigin} y1={yOrigin} x2={xDest} y2={yDest} style={{ stroke: "rgb(255,0,0)", strokeWidth: 2, zIndex: 1000 }} markerEnd="url(#arrowhead)" />
                </svg>)}
            <div style={{ marginTop: "12px", fontSize: "20px", width: minionBoardDimensions.width, display: "flex", justifyContent: "center", alignItems: "center" }}>{statusText}</div>
        </div>
    );
}


const exampleRequest = `
http://localhost:3001/minion-board?debugRoute=1&minionBoardProps={
    "minionsTop": [
        {
            "name": "Zombeh",
            "attack": 2,
            "hp": 4,
            "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
            "borderColor": "maroon"
        },
        {
            "name": "Zombeh",
            "attack": 2,
            "hp": 4,
            "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
            "borderColor": "green"
        }
    ],
    "minionsBot": [
        {
            "name": "Zombeh",
            "attack": 2,
            "hp": 4,
            "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
            "borderColor": "maroon"
        },
        {
            "name": "Zombeh",
            "attack": 2,
            "hp": 4,
            "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
            "borderColor": "green"
        }
    ],
    "arrow": {
        "direction": "topToBot",
        "originIndex": 0,
        "destIndex": 1
    }
}
`
if (exampleRequest !== null) {

}