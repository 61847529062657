import "./App.css";
import Card from "./Card.tsx";
import { downloadSvgAsPng, svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";

const roboto = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function Base() {
  const { width, height } = useLoaderData();
  const [svg, setSvg] = useState(null);
  const [pngURL, setPngURL] = useState(null);

  useEffect(() => {
    const generateSvg = async () => {
      const svgResult = await satori(<Card width={width} height={height} />, {
        width,
        height,
        fonts: [
          {
            name: "Roboto",
            data: await roboto
          }
        ]
      });
      const pngURL = await svgToPngURL(svgResult);
      setPngURL(pngURL);
      setSvg(svgResult);
    }
    generateSvg()
  }, [width, height]);


  const handleClick = async () => {
    const svg = await satori(<Card width={width} height={height} />, {
      width,
      height,
      fonts: [
        {
          name: "Roboto",
          data: await roboto
        }
      ]
    });
  };

  const svgString = `<svg width="100" height="100"><circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /></svg>`;

  return (
    <>
      <img src={pngURL}></img>
    </>
  );
}
