import React, { useEffect, useState } from "react";
import "./App.css";
import { Minion, MINION_HEIGHT, MINION_WIDTH } from "./Minion.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function MinionRoute() {

    const
        {
            minionProps,
            debugRoute,
        } = useLoaderData();
    const [pngURL, setPngURL] = useState(null);

    console.error({ minionProps })
    console.error({ MINION_HEIGHT })
    console.error({ MINION_WIDTH })

    useEffect(() => {
        const generateSvg = async () => {
            const svgResult = await satori(
                <Minion {...minionProps} />, {
                height: MINION_HEIGHT,
                width: MINION_WIDTH,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
        }
        generateSvg()
    }, [minionProps,
        debugRoute
    ]);

    return (
        <div style={{display: "flex", flexDirection:"column"}}>
            {debugRoute && debugRoute === "1" && (
                <Minion {...minionProps} />
            )}
            <img style={{height:MINION_HEIGHT, width:MINION_WIDTH}} src={pngURL!} alt={"Minion"}></img>
        </div>
    );
}

// http://localhost:3001/minion?debugRoute=1&minionProps={%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22maroon%22%20}