import "./App.css";
import { HeroMessageAndBeast } from "./HeroMessageAndBeast.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function HeroMessageAndBeastRoute() {
  const
    {
      heroMessageAndBeastProps,
      debugRoute,
    } = useLoaderData();

  const [pngURL, setPngURL] = useState(null);

  useEffect(() => {
    const generateSvg = async () => {
      const svgResult = await satori(
        <HeroMessageAndBeast {...heroMessageAndBeastProps} />, {
        height: heroMessageAndBeastProps.height,
        width: heroMessageAndBeastProps.width,
        fonts: [
          {
            name: "Alike",
            data: await alike
          }
        ]
      });
      const pngURL = await svgToPngURL(svgResult);
      setPngURL(pngURL);
    }
    generateSvg()
  }, [heroMessageAndBeastProps,
    debugRoute,
  ]);

  return (
    <>
      {debugRoute && debugRoute === "1" && (
        <HeroMessageAndBeast {...heroMessageAndBeastProps}/>
      )}
      <img src={pngURL!} alt={"HeroMessageAndBeast"}></img>
    </>
  );
}
