import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import BaseRoute from './BaseRoute.tsx';
import FarmRoute from './FarmRoute.tsx';
import HeroMessageRoute from './HeroMessageRoute.tsx';
import HeroMessageAndBeastRoute from './HeroMessageAndBeastRoute.tsx';
import BeastListRoute from './BeastListRoute.tsx';
import BeastRoute from './BeastRoute.tsx';
import HexagonGridRoute from './HexagonGridRoute.tsx';
import MinionBoardRoute from './MinionBoardRoute.tsx';
import MinionRoute from './MinionRoute.tsx';
import MinionListRoute from './MinionListRoute.tsx';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

async function loader({ request }) {
  const url = new URL(request.url);
  const height = parseInt(url.searchParams.get("height"));
  const width = parseInt(url.searchParams.get("width"));
  return { height, width };
}

async function loaderFarm({ request }) {
  const url = new URL(request.url);
  const farm_height = parseInt(url.searchParams.get("farm_height"))
  const farm_width = parseInt(url.searchParams.get("farm_width"))
  const farm_backgroundImgUrl = url.searchParams.get("farm_backgroundImgUrl")
  const banner_height = parseInt(url.searchParams.get("banner_height"))
  const banner_width = parseInt(url.searchParams.get("banner_width"))
  const banner_heroImgUrl = url.searchParams.get("banner_heroImgUrl")
  const banner_text = url.searchParams.get("banner_text")
  const banner_marginLeft = url.searchParams.get("banner_marginLeft")
  const banner_fontSize = url.searchParams.get("banner_fontSize")
  const banner_top = url.searchParams.get("banner_top")
  const footer_width = parseInt(url.searchParams.get("footer_width"))
  const footer_height = parseInt(url.searchParams.get("footer_height"))
  const footer_textArr = JSON.parse(url.searchParams.get("footer_textArr"))
  const footer_fontSize = url.searchParams.get("footer_fontSize")
  const footer_marginPx = url.searchParams.get("footer_marginPx")
  const debugRoute = url.searchParams.get("debugRoute")

  const parsedLoaderFarmParams = {
    farm_height,
    farm_width,
    farm_backgroundImgUrl,
    banner_height,
    banner_width,
    banner_heroImgUrl,
    banner_text,
    banner_marginLeft,
    banner_fontSize,
    banner_top,
    footer_width,
    footer_height,
    footer_textArr,
    footer_fontSize,
    footer_marginPx,
    debugRoute,
  }
  console.error({ parsedLoaderFarmParams })
  return parsedLoaderFarmParams;
}

async function loaderHeroMessage({ request }) {
  const url = new URL(request.url);
  const backgroundImg_height = parseInt(url.searchParams.get("height"))
  const backgroundImg_width = parseInt(url.searchParams.get("width"))
  const backgroundImg_url = url.searchParams.get("backgroundImg_url")
  const banner_height = parseInt(url.searchParams.get("banner_height"))
  const banner_width = parseInt(url.searchParams.get("banner_width"))
  const banner_heroImgUrl = url.searchParams.get("banner_heroImgUrl")
  const banner_text = url.searchParams.get("banner_text")
  const banner_marginLeft = url.searchParams.get("banner_marginLeft")
  const banner_marginRight = url.searchParams.get("banner_marginRight")
  const banner_fontSize = url.searchParams.get("banner_fontSize")
  const banner_top = url.searchParams.get("banner_top")
  const debugRoute = url.searchParams.get("debugRoute")

  const parsedLoaderHeroMessageParams = {
    backgroundImg_height,
    backgroundImg_width,
    backgroundImg_url,
    banner_height,
    banner_width,
    banner_heroImgUrl,
    banner_text,
    banner_marginLeft,
    banner_marginRight,
    banner_fontSize,
    banner_top,
    debugRoute,
  }
  console.error({ parsedLoaderHeroMessageParams })
  return parsedLoaderHeroMessageParams;
}

async function loaderBeast({ request }) {
  const url = new URL(request.url);
  const width = parseInt(url.searchParams.get("width"))
  const height = parseInt(url.searchParams.get("height"))
  const imgUrl = url.searchParams.get("imgUrl")
  const imgHeight = parseInt(url.searchParams.get("imgHeight"))
  const imgWidth = parseInt(url.searchParams.get("imgWidth"))
  const title = url.searchParams.get("title")
  const titleFontSize = url.searchParams.get("titleFontSize")
  const iconImgUrlTextArr = JSON.parse(url.searchParams.get("iconImgUrlTextArr"))
  const debugRoute = url.searchParams.get("debugRoute")

  const parsedLoaderBeastParams = {
    width,
    height,
    imgUrl,
    imgHeight,
    imgWidth,
    title,
    titleFontSize,
    iconImgUrlTextArr,
    debugRoute,
  }
  console.error({ parsedLoaderBeastParams })
  return parsedLoaderBeastParams;
}

async function loaderHeroMessageAndBeast({ request }) {
  const url = new URL(request.url);
  const heroMessageAndBeastProps = {
    height: parseInt(url.searchParams.get("height")),
    width: parseInt(url.searchParams.get("width")),
    backgroundImgUrl: url.searchParams.get("backgroundImgUrl"),
    bannerProps: {
      width: parseInt(url.searchParams.get("ban_width")),
      height: parseInt(url.searchParams.get("ban_height")),
      heroImgUrl: url.searchParams.get("ban_heroImgUrl"),
      text: url.searchParams.get("ban_text"),
      marginLeft: url.searchParams.get("ban_marginLeft"),
      fontSize: url.searchParams.get("ban_fontSize"),
      top: url.searchParams.get("ban_top"),
      marginRight: url.searchParams.get("ban_marginRight"),
    },
    beastProps: {
      height: parseInt(url.searchParams.get("bst_height")),
      width: parseInt(url.searchParams.get("bst_width")),
      imgUrl: url.searchParams.get("bst_imgUrl"),
      imgHeight: parseInt(url.searchParams.get("bst_imgHeight")),
      imgWidth: parseInt(url.searchParams.get("bst_imgWidth")),
      title: url.searchParams.get("bst_title"),
      titleFontSize: url.searchParams.get("bst_titleFontSize"),
      iconSize: parseInt(url.searchParams.get("bst_iconSize")),
      iconFontSize: url.searchParams.get("bst_iconFontSize"),
      iconImgUrlTextArr: JSON.parse(url.searchParams.get("bst_iconImgUrlTextArr")),
    }
  }
  const debugRoute = url.searchParams.get("debugRoute")

  const parsedLoaderHeroMessageAndBeastParams = {
    heroMessageAndBeastProps,
    debugRoute,
  }
  console.error({ parsedLoaderHeroMessageAndBeastParams })
  return parsedLoaderHeroMessageAndBeastParams;
}

async function loaderBeastList({ request }) {
  const url = new URL(request.url);
  const beasts = JSON.parse(url.searchParams.get("beasts"))
  const debugRoute = url.searchParams.get("debugRoute")

  const parsedBeastListParams = {
    beasts,
    debugRoute,
  }
  console.error({ parsedBeastListParams })
  return parsedBeastListParams;
}

async function loaderHexagonGrid({ request }) {
  const url = new URL(request.url);
  const encodedUrls = url.searchParams.get("urls")
  const urlsJson = atob(encodedUrls)
  const urls = JSON.parse(urlsJson)
  // The urls are encoded
  const debugRoute = url.searchParams.get("debugRoute")
  const height = 400
  const width = 400

  const parsedHexagonGridParams = {
    hexagonProps: {urls,
    debugRoute,
    height,
    width,
    },
    debugRoute,
  }
  console.error({ parsedHexagonGridParams })
  return parsedHexagonGridParams;
}

async function loaderMinionBoard({ request }) {
  const url = new URL(request.url);
  const minionBoardJSON = url.searchParams.get("minionBoardProps")
  const minionBoardProps = JSON.parse(minionBoardJSON)

  const debugRoute = url.searchParams.get("debugRoute")

  const parsedMinionBoardProps = {
    minionBoardProps: minionBoardProps,
    debugRoute: debugRoute,
  }
  console.error({ parsedMinionBoardProps })
  return parsedMinionBoardProps;
}

async function loaderMinion({ request }) {
  const url = new URL(request.url);
  const minionJSON = url.searchParams.get("minionProps")
  const minionProps = JSON.parse(minionJSON)

  const debugRoute = url.searchParams.get("debugRoute")

  const parsedMinionProps = {
    minionProps: minionProps,
    debugRoute: debugRoute,
  }
  console.error({ parsedMinionProps })
  return parsedMinionProps;
}

async function loaderMinionList({ request }) {
  const url = new URL(request.url);
  const minionListJSON = url.searchParams.get("minionListProps")
  const minionListProps = JSON.parse(minionListJSON)

  const debugRoute = url.searchParams.get("debugRoute")

  const parsedMinionListProps = {
    minionListProps: minionListProps,
    debugRoute: debugRoute,
  }
  console.error({ parsedMinionListProps })
  return parsedMinionListProps;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: loader,
  },
  {
    path: "/base",
    element: <BaseRoute />,
    loader: loader,
  },
  {
    path: "/farm",
    element: <FarmRoute />,
    loader: loaderFarm,
  },
  {
    path: "/hero-message",
    element: <HeroMessageRoute />,
    loader: loaderHeroMessage,
  },
  {
    path: "/beast",
    element: <BeastRoute />,
    loader: loaderBeast,
  },
  {
    path: "/hero-message-and-beast",
    element: <HeroMessageAndBeastRoute />,
    loader: loaderHeroMessageAndBeast,
  },
  {
    path: "/beast-list",
    element: <BeastListRoute />,
    loader: loaderBeastList,
  },
  { path: "/hexagon",
    element: <HexagonGridRoute />,
    loader: loaderHexagonGrid,
  },
  { path: "/minion-board",
    element: <MinionBoardRoute />,
    loader: loaderMinionBoard,
  },
  { path: "/minion",
    element: <MinionRoute />,
    loader: loaderMinion,
  },
  { path: "/minion-list",
    element: <MinionListRoute />,
    loader: loaderMinionList,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
