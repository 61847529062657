import React from 'react';

const styles = {
    hexagonWrapper: {
        width: '200px',
        height: '230px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
    },
    hexagon: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        //clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)',
    },
    image: {
        width: '200px',//'auto',
        height: '200px', //'100%',
    },
};


function getRandomHex() {
    return '#' + Math.random().toString(16).substr(-6);
}

const HexagonImage = ({ imageUrl }) => {
    return (
        <>
            <div style={styles.hexagonWrapper}>
                <div style={{ ...styles.hexagon, /*background: getRandomHex() */ }}>
                    <img src={imageUrl} alt="Hexagon" style={styles.image} />
                </div>
            </div>
            {/*<img src='https://upload.wikimedia.org/wikipedia/commons/f/f1/Flag_Map_Of_Ukraine_%28Without_Crimea%29.png' />*/}
        </>
    );
};

export default HexagonImage;
