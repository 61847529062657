import React, { useEffect, useState } from "react";
import "./App.css";
import { MinionBoard, getMinionBoardHeightWidth } from "./MinionBoard.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function MinionBoardRoute() {

    const
        {
            minionBoardProps,
            debugRoute,
        } = useLoaderData();
    const [pngURL, setPngURL] = useState(null);

    console.error({ minionBoardProps })
    const minionBoardDimensions = getMinionBoardHeightWidth(minionBoardProps.minionsTop, minionBoardProps.minionsBot);
    console.error({ minionBoardDimensions })

    useEffect(() => {
        const generateSvg = async () => {
            const svgResult = await satori(
                <MinionBoard {...minionBoardProps} />, {
                height: minionBoardDimensions.height,
                width: minionBoardDimensions.width,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
        }
        generateSvg()
    }, [minionBoardProps,
        debugRoute,
        minionBoardDimensions.height,
        minionBoardDimensions.width
    ]);

    return (
        <>
            {debugRoute && debugRoute === "1" && (
                <MinionBoard {...minionBoardProps} />
            )}
            <img src={pngURL!} style={{height:minionBoardDimensions.height, width:minionBoardDimensions.width}} alt={"MinionBoard"}></img>
        </>
    );
}

// http://localhost:3001/minion-board?debugRoute=1&minionBoardProps={%20%22minionsTop%22:%20[%20{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22maroon%22%20},%20{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22green%22%20}%20],%20%22minionsBot%22:%20[%20{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22maroon%22%20},%20{%20%22name%22:%20%22Zombeh%22,%20%22attack%22:%202,%20%22hp%22:%204,%20%22imgUrl%22:%20%22https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937%22,%20%22borderColor%22:%20%22green%22%20}%20],%20%22arrow%22:%20{%20%22direction%22:%20%22topToBot%22,%20%22originIndex%22:%200,%20%22destIndex%22:%201%20}%20}