import React from 'react';
import PropTypes from 'prop-types';

export const Footer = ({ width, height, textArr, fontSize, marginPx }) => {
    console.error({ width, height, textArr, fontSize, marginPx })
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: height, width: width, backgroundColor: "black" }}>
            {textArr.map((text, index) => {
                return (<p key={"footer-text-" + index} style={{
                    marginLeft: marginPx,
                    marginRight: marginPx,
                    color: "#FFF",
                    fontSize: fontSize,
                    fontFamily: "Alike",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "125%",
                }}>
                    {text}
                </p>)
            }
            )}
        </div>
    );
}

Footer.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    fontSize: PropTypes.string,
    text: PropTypes.array,
};

Footer.defaultProps = {
    height: 60,
    width: 800,
    fontSize: "26px",
    textArr: ["Week: 2", "Farm: B", "¥: 2"],
};
