import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from "./Banner.tsx";
import { Footer } from './Footer.tsx';

export const Farm = ({ height, width, backgroundImgUrl, bannerProps, footerProps }) => {
    return (
        <div style={{height: height + footerProps.height, display: "flex", flexDirection: "column"}}>
            <div style={{ display: "flex", height: height, width: width, backgroundImage: `url(${backgroundImgUrl})`, backgroundSize: "cover" }}>
                <Banner {...bannerProps}></Banner>
            </div>
            <Footer {...footerProps}></Footer>
        </div>
    );
}


Farm.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    backgroundImgUrl: PropTypes.string,
    bannerProps: Banner.propTypes,
};


Farm.defaultProps = {
    height: 534,
    width: 800,
    backgroundImgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Farm800w.png",
    bannerProps: {
        height: 131,
        width: 800,
        heroImgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png",
        text: "Rose: What should we do this week?",
        marginLeft: "100px",
        fontSize: "26px",
        top: "34px",
    },
    footerProps: {
        width: 800,
        height: 60,
        textArr: ["Week: 2", "Farm: B", "¥: 2000"],
        fontSize: "26px",
        marginPx: "16px",
    }
};

// ?farm_height=600&farm_width=800&banner_height=131&banner_width=800&banner_heroImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png&banner_text=What should we do this week?&banner_marginLeft=100px&banner_fontSize=26px&banner_top=34px

// http://localhost:3001
// https://14f60fbc.imgrender.pages.dev


// /farm?farm_height=534&farm_width=800&banner_height=131&banner_width=800&banner_heroImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png&banner_text=What%20should%20we%20do%20this%20week?&banner_marginLeft=100px&banner_fontSize=26px&banner_top=34px&farm_backgroundImgUrl=https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/backgrounds/Farm800w.png&footer_width=800&footer_height=60&footer_textArr=["Week: 2", "Farm: B", "¥: 2000"]&footer_fontSize=26px&footer_marginPx=16px&debugRoute=1