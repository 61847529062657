import "./App.css";
import { Beast } from "./Beast.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function BeastRoute() {
    const
        {
            width,
            height,
            imgUrl,
            imgHeight,
            imgWidth,
            title,
            titleFontSize,
            iconImgUrlTextArr,
            debugRoute,
        } = useLoaderData();

    const [pngURL, setPngURL] = useState(null);

    useEffect(() => {
        const generateSvg = async () => {
            const svgResult = await satori(
                <Beast
                    width={width}
                    height={height}
                    imgUrl={imgUrl}
                    imgHeight={imgHeight}
                    imgWidth={imgWidth}
                    title={title}
                    titleFontSize={titleFontSize}
                    iconImgUrlTextArr={iconImgUrlTextArr}
                />, {
                height: height,
                width: width,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
        }
        generateSvg()
    }, [width,
        height,
        imgUrl,
        imgHeight,
        imgWidth,
        title,
        titleFontSize,
        iconImgUrlTextArr,
        debugRoute,
    ]);

    return (
        <>
            {debugRoute && debugRoute === "1" && (
                <Beast
                    width={width}
                    height={height}
                    imgUrl={imgUrl}
                    imgHeight={imgHeight}
                    imgWidth={imgWidth}
                    title={title}
                    titleFontSize={titleFontSize}
                    iconImgUrlTextArr={iconImgUrlTextArr}
                />
            )}
            <img src={pngURL!} alt={"Beast"}></img>
        </>
    );
}
