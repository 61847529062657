import React from 'react';
import sword from './img/sword_023.svg'
import shield from './img/shield_009.svg'

export const MINION_WIDTH = 117
export const MINION_HEIGHT = 170

export interface MinionProps {
    name: string;
    imgUrl: string;
    attack?: number;
    borderColor?: string;
    debug?: boolean;
    hp?: number;
}

export const Minion = ({
    name,
    imgUrl,
    attack,
    hp,
    borderColor = "white",
    debug = false,
}: MinionProps) => {
    return (
        <div key={"minion-" + name} style={{ zIndex: -1, minWidth: "117px", maxWidth: "117px", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
            <div style={{ zIndex: -1, display: "flex", minHeight: "117px", maxHeight: "117px", minWidth: "117px", maxWidth: "117px", position: "relative" }}>
                {imgUrl && (
                    <div
                        style={{ zIndex: -1, display: "flex", clipPath: "polygon(50% 0, 92% 25%, 92% 75%, 50% 97%, 8% 75%, 8% 25%)", position: "absolute", minHeight: "105px", maxHeight: "105px", minWidth: "105px", maxWidth: "105px", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>

                        <img src={imgUrl} alt={name} style={{ zIndex: -1, minHeight: "105px", maxHeight: "105px", minWidth: "105px", maxWidth: "105px" }} />
                    </div>
                )}
            </div>
            <svg style={{ display: "flex", position: "absolute", left: "6px", zIndex: -1 }} width="105" height="117" viewBox="0 0 105 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.2125 3.34607C50.5308 1.43024 54.6192 1.43023 57.9375 3.34607L97.9399 26.4415C101.258 28.3573 103.302 31.8979 103.302 35.7296V81.9204C103.302 85.7521 101.258 89.2927 97.9399 91.2086L57.9375 114.304C54.6192 116.22 50.5308 116.22 47.2125 114.304L7.21007 91.2086C3.89174 89.2927 1.84756 85.7521 1.84756 81.9204V35.7296C1.84756 31.8979 3.89173 28.3573 7.21006 26.4415L47.2125 3.34607Z" strokeWidth="2" stroke={borderColor} />
            </svg>
            <svg style={{ display: "flex", position: "absolute", left: "12px", top: "6px", zIndex: -1 }} filter={"drop-shadow(0 2px 8px rgba(255, 255, 255, 1))"} width="93" height="104" viewBox="0 0 93 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50.95 2.25562L87.3158 23.2515C90.0231 24.8145 91.6908 27.7031 91.6908 30.8292V72.8208C91.6908 75.9469 90.0231 78.8355 87.3158 80.3986L50.95 101.394C48.2427 102.957 44.9072 102.957 42.2 101.394L5.8341 80.3986C3.12684 78.8355 1.4591 75.9469 1.4591 72.8208V30.8292C1.4591 27.7031 3.12684 24.8145 5.8341 23.2515L42.2 2.25562C44.9072 0.692584 48.2427 0.692583 50.95 2.25562Z" fill="url(#pattern0)" strokeWidth="2" stroke={borderColor} />
            </svg>
            <div style={{ display: "flex", minWidth: "80px", maxWidth: "80px", justifyContent: "center" }}>
                <div>{name}</div>
            </div>
            <div style={{ display: "flex", minWidth: "80px", maxWidth: "80px", justifyContent: "space-between" }}>
                {attack && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img style={{ height: "24px", width: "24px" }} src={sword} alt="sword" />
                        <div style={{ display: "flex" }}>{attack}</div>
                    </div>
                )}
                {hp && (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <img style={{ height: "24px", width: "24px" }} src={shield} alt="shield" />
                        <div style={{ display: "flex" }}>{hp}</div>
                    </div>
                )}
            </div>
        </div>
    );
}


const exampleRequest = `
http://localhost:3001/minion?debugRoute=1&minionProps={
    "name": "Zombeh",
    "attack": 2,
    "hp": 4,
    "imgUrl": "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/39c42792-ecc6-445a-9a4b-ab95f7908937",
    "borderColor": "maroon"
}
`
if (exampleRequest !== null) {

}