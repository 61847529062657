import React from 'react';
import { Banner } from "./Banner.tsx";
import { Beast } from "./Beast.tsx";

export const HeroMessageAndBeast = ({
    height,
    width,
    backgroundImgUrl,
    bannerProps,
    beastProps,
     }) => {
    return (
        <div style={{ height: height, display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", height: height, width: width, backgroundImage: `url(${backgroundImgUrl})`, backgroundSize: "cover" }}>
                <Banner {...bannerProps}></Banner>
                <div style={{display: "flex", flexDirection: "row-reverse"}}>
                    <Beast {...beastProps}/>
                </div>
            </div>
        </div>
    );
}
