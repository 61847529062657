import "./App.css";
import HexagonGrid from "./HexagonGrid.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function HexagonGridRoute() {
    const
        {
            hexagonProps,
            debugRoute,
        } = useLoaderData();
    const loadingData = useLoaderData();
    console.error(loadingData)

    // Convert the hexagon grid url params to be the format we expect
    console.error(hexagonProps)

    const [pngURL, setPngURL] = useState(null);
    const [svg, setSvg] = useState(null); // <svg... string that gets generated

    useEffect(() => {
        const generateSvg = async () => {
            const svgResult = await satori(
                <HexagonGrid {...hexagonProps} />, {
                height: 1150,
                width: 1800,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
            setSvg(`data:image/svg+xml;base64,${btoa(svgResult)}`); // for debugging if PNG conversion was the issue
        }
        generateSvg()
    }, [hexagonProps,
        debugRoute,
    ]);

    return (
        <>
            {debugRoute && debugRoute === "1" && (
                <HexagonGrid {...hexagonProps} />
            )}
            <img src={pngURL!} alt={"HexagonGrid"}></img>
        </>
    );
}
