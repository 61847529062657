import React from 'react';
import PropTypes from 'prop-types';

// fontFamily: "Inter",

export const Banner = ({ 
    width, 
    height, 
    heroImgUrl, 
    text, 
    marginLeft, 
    fontSize, 
    top, 
    marginRight}) => {
    return (
        <div style={{ position: "relative", top: top, display: "flex", height: height, width: width, background: "rgba(0, 0, 0, 0.75)" }}>
            <img src={heroImgUrl} style={{ height: height, width: height }} />
            <p style={{
                marginLeft: marginLeft,
                marginRight: marginRight,
                color: "#FFF",
                fontSize: fontSize,
                fontFamily: "Alike",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "120%",
                overflow: "hidden",
            }}>{text}</p>
        </div>
    );
}

Banner.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    heroImgUrl: PropTypes.string,
    text: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    fontSize: PropTypes.string,
    top: PropTypes.string,
};

Banner.defaultProps = {
    height: 307,
    width: 1871,
    heroImgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/characters/Rose.png",
    text: "What should we do this week?",
    marginLeft: "100px",
    marginRight: "60px",
    fontSize: "60px",
    top: "60px",
};
