import React from 'react';
import { Minion, MINION_WIDTH, MINION_HEIGHT } from './Minion.tsx';

export const MinionList = ({ minions }) => {
    if (!minions) {
        return null;
    }
    const totalWidth = MINION_WIDTH * 7;
    return (
        <div style={{width: totalWidth, height:MINION_HEIGHT, display: "flex"}}>
            {minions.map((minion, ii) => {
                return <Minion key={`${minion.name} ${ii}`} {...minion}/>
            })}
        </div>
    );
}

