import "./App.css";
import { BeastList } from "./BeastList.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function BeastListRoute() {

    const
        {
            beasts,
            debugRoute,
        } = useLoaderData();
        const [pngURL, setPngURL] = useState(null);

    useEffect(() => {
        const totalHeight = beasts.map((b) => { return b.height }).reduce((acc, curr) => acc + curr, 0);
        const generateSvg = async () => {
            const svgResult = await satori(
                <BeastList {...{beasts: beasts}} />, {
                height: totalHeight,
                width: beasts[0].width,
                fonts: [
                    {
                        name: "Alike",
                        data: await alike
                    }
                ]
            });
            const pngURL = await svgToPngURL(svgResult);
            setPngURL(pngURL);
        }
        generateSvg()
    }, [beasts,
        debugRoute,
    ]);

    return (
        <>
            {debugRoute && debugRoute === "1" && (
                <BeastList {...{beasts: beasts}} />
            )}
            <img src={pngURL!} alt={"BeastList"}></img>
        </>
    );
}
