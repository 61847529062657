import React from 'react';
import PropTypes from 'prop-types';

export const Beast = ({
    height,
    width,
    imgUrl,
    imgHeight,
    imgWidth,
    title,
    titleFontSize,
    iconImgUrlTextArr,
    iconSize,
    iconFontSize,
}) => {
    const randomString = Math.random().toString(36).substring(2);
    return (
        <div key={randomString} style={{ height: height, width: width, backgroundColor: "rgba(0, 0, 0, 0.75)", display: "flex", flexDirection: "row" }}>
            <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <img style={{ height: imgHeight, width: imgWidth }} src={imgUrl}></img>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: height - imgHeight,
                    width: imgWidth,
                }}>
                    <p style={{
                        margin: 0,
                        color: "#FFF",
                        fontSize: titleFontSize,
                        fontFamily: "Alike",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "220%",
                        overflow: "hidden",    
                    }}>{title}</p>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", margin: "6px"}}>
                {iconImgUrlTextArr.map((iconImgUrlText, index) => {
                    return (
                        <div key={index + "-icon-text"} style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            height: iconSize,
                        }}>
                            <img style={{ height: iconSize, width: iconSize }} src={iconImgUrlText[0]}></img>
                            <p style={{
                                marginLeft: "3px",
                                height: iconSize,
                                width: "100%",
                                color: "#FFF",
                                fontSize: iconFontSize,
                                fontFamily: "Alike",
                                fontStyle: "normal",
                                fontWeight: 400,
                            }}>{iconImgUrlText[1]}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

// Amsterdoggehhhhhh (max 17 chars)

Beast.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    imgUrl: PropTypes.string,
    imgHeight: PropTypes.number,
    imgWidth: PropTypes.number,
    title: PropTypes.string,
    titleFontSize: PropTypes.string,
    iconSize: PropTypes.number,
    iconFontSize: PropTypes.string,
    iconImgUrlTextArr: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};


Beast.defaultProps = {
    width: 272,
    height: 230,
    imgUrl: "https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/fal/f93b7a89-6673-47dd-a47f-3ec7a9d27a60",
    imgHeight: 190,
    imgWidth: 190,
    title: "Amsterdoggeh",
    titleFontSize: "18px",
    iconImgUrlTextArr: [
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/EnergyPotion.png", "2"],
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/HealthPotion.png", "10"],
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/Sword.png", "777"],
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/Shield.png", "3"],
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/Wind.png", "40"],
        ["https://qmitbwoipozxsvdecekc.supabase.co/storage/v1/object/public/images/images/icons/Scroll.png", "5"],
    ],
    iconSize: 31,
    iconFontSize: "25px",
};