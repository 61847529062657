import "./App.css";
import { HeroMessage } from "./HeroMessage.tsx";
import { svgToPngURL } from "./svg-utils.tsx";
import satori from "satori";
import { useLoaderData } from "react-router-dom";
import React, { useEffect, useState } from "react";

const alike = fetch("/fonts/AlikeAngular-Regular.ttf").then((res) => res.arrayBuffer());

export default function HeroMessageRoute() {
  const
    {
      backgroundImg_height,
      backgroundImg_width,
      backgroundImg_url,
      banner_height,
      banner_width,
      banner_heroImgUrl,
      banner_text,
      banner_marginLeft,
      banner_marginRight,
      banner_fontSize,
      banner_top,
      debugRoute,
    } = useLoaderData();

  const [pngURL, setPngURL] = useState(null);

  useEffect(() => {
    const generateSvg = async () => {
      const svgResult = await satori(
        <HeroMessage
          height={backgroundImg_height}
          width={backgroundImg_width}
          backgroundImgUrl={backgroundImg_url}
          bannerProps={{
            height: banner_height,
            width: banner_width,
            heroImgUrl: banner_heroImgUrl,
            text: banner_text,
            marginLeft: banner_marginLeft,
            marginRight: banner_marginRight,
            fontSize: banner_fontSize,
            top: banner_top,
          }}
        />, {
        height: backgroundImg_height,
        width: backgroundImg_width,
        fonts: [
          {
            name: "Alike",
            data: await alike
          }
        ]
      });
      const pngURL = await svgToPngURL(svgResult);
      setPngURL(pngURL);
    }
    generateSvg()
  }, [backgroundImg_height,
    backgroundImg_width,
    backgroundImg_url,
    banner_height,
    banner_width,
    banner_heroImgUrl,
    banner_text,
    banner_marginLeft,
    banner_marginRight,
    banner_fontSize,
    banner_top,
    debugRoute,
  ]);

  return (
    <>
      {debugRoute && debugRoute === "1" && (
        <HeroMessage
          height={backgroundImg_height}
          width={backgroundImg_width}
          backgroundImgUrl={backgroundImg_url}
          bannerProps={{
            height: banner_height,
            width: banner_width,
            heroImgUrl: banner_heroImgUrl,
            text: banner_text,
            marginLeft: banner_marginLeft,
            marginRight: banner_marginRight,
            fontSize: banner_fontSize,
            top: banner_top,
          }}
        />
      )}
      <img src={pngURL!} alt={"HeroMessage"}></img>
    </>
  );
}
